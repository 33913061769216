/* eslint-disable react-hooks/exhaustive-deps */
// App.js
import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
import * as cocossd from '@tensorflow-models/coco-ssd';

const App = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const offscreenCanvasRef = useRef(document.createElement('canvas'));

  const [model, setModel] = useState(null);
  const [coordinatesList, setCoordinatesList] = useState([]);
  const coordinatesListRef = useRef([]);

  // Image adjustment states
  const [brightness, setBrightness] = useState(1);
  const [contrast, setContrast] = useState(1);
  const [saturate, setSaturate] = useState(1);
  const [hue, setHue] = useState(0);

  // Pupil configuration states
  const [pupilWidthRatio, setPupilWidthRatio] = useState(0.125); // default value 1/8
  const [minPupilHeightRatio, setMinPupilHeightRatio] = useState(0.125); // default value 1/8
  const [pupilScale, setPupilScale] = useState(1);

  // Smoothing configuration
  const [maxPupilSpeed, setMaxPupilSpeed] = useState(10); // pixels per frame

  // Pupil position ref for smoothing
  const pupilPositionRef = useRef({ x: null, y: null });

  // Mode state
  const [isCatEyeMode, setIsCatEyeMode] = useState(false);

  // Load the COCO-SSD model
  useEffect(() => {
    const loadModel = async () => {
      try {
        await tf.ready();
        const net = await cocossd.load();
        setModel(net);
        console.log('Model loaded successfully');
      } catch (error) {
        console.error('Error loading model:', error);
      }
    };
    loadModel();
  }, []);

  // Detection loop
  useEffect(() => {
    let animationFrameId;

    const detectObjects = async () => {
      if (
        webcamRef.current &&
        webcamRef.current.video.readyState === 4 &&
        model !== null
      ) {
        const video = webcamRef.current.video;
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        // Set dimensions
        webcamRef.current.video.width = videoWidth;
        webcamRef.current.video.height = videoHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
        offscreenCanvasRef.current.width = videoWidth;
        offscreenCanvasRef.current.height = videoHeight;

        // Get contexts
        const offscreenCtx = offscreenCanvasRef.current.getContext('2d');
        const displayCtx = canvasRef.current.getContext('2d');

        // Apply filters
        offscreenCtx.filter = `brightness(${brightness}) contrast(${contrast}) saturate(${saturate}) hue-rotate(${hue}deg)`;

        // Draw video frame to off-screen canvas with filters
        offscreenCtx.drawImage(video, 0, 0, videoWidth, videoHeight);

        // Get adjusted image data
        const imageData = offscreenCtx.getImageData(
          0,
          0,
          videoWidth,
          videoHeight
        );

        // Create a tensor from the image data
        const imgTensor = tf.browser.fromPixels(imageData);

        // Perform object detection on the adjusted image tensor
        const detections = await model.detect(imgTensor);

        // Dispose the tensor to free memory
        imgTensor.dispose();

        // Filter for desired classes
        const desiredClasses = ['person', 'car', 'dog', 'bicycle'];
        const filteredDetections = detections.filter((detection) =>
          desiredClasses.includes(detection.class)
        );

        // Update tracking
        handleDetections(filteredDetections, videoWidth, videoHeight);

        // Clear previous drawings
        displayCtx.clearRect(0, 0, videoWidth, videoHeight);

        if (isCatEyeMode) {
          // Draw cat eye
          drawCatEye(displayCtx, videoWidth, videoHeight);
        } else {
          // Draw adjusted frame to display canvas
          displayCtx.putImageData(imageData, 0, 0);

          // Draw bounding boxes
          drawDetections(filteredDetections);
        }
      }
      animationFrameId = requestAnimationFrame(detectObjects);
    };

    if (model) {
      detectObjects();
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [
    model,
    brightness,
    contrast,
    saturate,
    hue,
    isCatEyeMode,
    pupilScale,
    pupilWidthRatio,
    minPupilHeightRatio,
    maxPupilSpeed,
  ]);

  // Handle detections
  const handleDetections = (detections, videoWidth, videoHeight) => {
    const objectsToTrack = detections.slice(0, 3);

    const coords = objectsToTrack.map((obj) => {
      const x = obj.bbox[0] + obj.bbox[2] / 2;
      const y = obj.bbox[1] + obj.bbox[3] / 2;
      const area = obj.bbox[2] * obj.bbox[3]; // width * height
      return {
        x: Math.round(x),
        y: Math.round(y),
        class: obj.class,
        area: area,
      };
    });
    setCoordinatesList(coords);
    coordinatesListRef.current = coords;
  };

  // Draw bounding boxes and labels
  const drawDetections = (detections) => {
    const ctx = canvasRef.current.getContext('2d');

    detections.forEach((detection) => {
      const [x, y, width, height] = detection.bbox;
      const text = `${detection.class} (${Math.round(
        detection.score * 100
      )}%)`;

      // Draw bounding box
      ctx.strokeStyle = '#00FF00';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);

      // Draw label background
      ctx.fillStyle = '#00FF00';
      const textWidth = ctx.measureText(text).width;
      const textHeight = parseInt('16px', 10);
      ctx.fillRect(x, y - textHeight, textWidth + 4, textHeight + 4);

      // Draw text
      ctx.fillStyle = '#000000';
      ctx.font = '16px Arial';
      ctx.fillText(text, x + 2, y - 2);
    });
  };

  // Draw the cat eye with smoothing
  const drawCatEye = (ctx, width, height) => {
    // Fill the canvas with black background
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, width, height);

    // Draw the cat eye
    const eyeCenterX = width / 2;
    const eyeCenterY = height / 2;
    const eyeRadius = Math.min(width, height) / 4; // Adjust as needed

    // Draw eye outline
    ctx.beginPath();
    ctx.arc(eyeCenterX, eyeCenterY, eyeRadius, 0, 2 * Math.PI);
    ctx.fillStyle = '#FFFF00'; // Yellow eye
    ctx.fill();
    ctx.closePath();

    // Define margin within the eye circle
    const eyeMargin = eyeRadius * 0.1; // 10% margin

    // Compute min and max pupil sizes using ratios
    const minPupilWidth = eyeRadius * pupilWidthRatio;
    const maxPupilWidth = minPupilWidth; // Keep width constant or adjust if desired
    const minPupilHeight = eyeRadius * minPupilHeightRatio;
    const maxPupilHeight = eyeRadius / 2; // Could also make this adjustable

    // Default pupil size
    let pupilWidth = minPupilWidth;
    let pupilHeight = minPupilHeight;

    // Target pupil position
    let targetPupilX = eyeCenterX;
    let targetPupilY = eyeCenterY;

    if (coordinatesListRef.current.length > 0) {
      const targetX = coordinatesListRef.current[0].x;
      const targetY = coordinatesListRef.current[0].y;
      const area = coordinatesListRef.current[0].area;

      // Calculate angle to the object
      const dx = targetX - eyeCenterX;
      const dy = targetY - eyeCenterY;
      const angle = Math.atan2(dy, dx);

      // Pupil movement range (eyeRadius - pupil max size - margin)
      const pupilMovementRadius = eyeRadius - maxPupilWidth - eyeMargin;

      // Target pupil position
      targetPupilX = eyeCenterX + pupilMovementRadius * Math.cos(angle);
      targetPupilY = eyeCenterY + pupilMovementRadius * Math.sin(angle);

      // Ensure pupil stays within eye circle with margin
      const distanceFromCenter = Math.hypot(
        targetPupilX - eyeCenterX,
        targetPupilY - eyeCenterY
      );
      if (distanceFromCenter + maxPupilWidth > eyeRadius - eyeMargin) {
        const scaleFactor =
          (eyeRadius - eyeMargin - maxPupilWidth) / distanceFromCenter;
        targetPupilX = eyeCenterX + (targetPupilX - eyeCenterX) * scaleFactor;
        targetPupilY = eyeCenterY + (targetPupilY - eyeCenterY) * scaleFactor;
      }

      // Pupil size based on area
      const minArea = width * height * 0.005; // 0.5% of the frame
      const maxArea = width * height * 0.5; // 50% of the frame

      let normalizedArea = (area - minArea) / (maxArea - minArea);
      normalizedArea = Math.max(0, Math.min(1, normalizedArea)); // Clamp between 0 and 1

      // Invert normalizedArea so that larger area corresponds to larger pupil size
      const adjustedArea = 1 - normalizedArea;

      pupilHeight =
        minPupilHeight +
        (maxPupilHeight - minPupilHeight) * adjustedArea * pupilScale;

      // Ensure pupilHeight is within min and max bounds
      pupilHeight = Math.max(
        minPupilHeight,
        Math.min(maxPupilHeight, pupilHeight)
      );

      pupilWidth = minPupilWidth; // Keep pupil width constant or make adjustable
    } else {
      // If no object detected, return pupil to center smoothly
      targetPupilX = eyeCenterX;
      targetPupilY = eyeCenterY;
    }

    // Initialize pupil position if not set
    if (pupilPositionRef.current.x === null) {
      pupilPositionRef.current.x = eyeCenterX;
      pupilPositionRef.current.y = eyeCenterY;
    }

    // Smoothly move the pupil towards the target position
    const deltaX = targetPupilX - pupilPositionRef.current.x;
    const deltaY = targetPupilY - pupilPositionRef.current.y;
    const distance = Math.hypot(deltaX, deltaY);

    // Limit movement per frame
    const maxMovement = maxPupilSpeed;
    if (distance <= maxMovement) {
      // Move directly to target
      pupilPositionRef.current.x = targetPupilX;
      pupilPositionRef.current.y = targetPupilY;
    } else {
      // Move towards target
      pupilPositionRef.current.x += (deltaX / distance) * maxMovement;
      pupilPositionRef.current.y += (deltaY / distance) * maxMovement;
    }

    // Use the smoothed pupil position for drawing
    const pupilX = pupilPositionRef.current.x;
    const pupilY = pupilPositionRef.current.y;

    // Draw pupil as vertical ellipse
    ctx.beginPath();
    ctx.ellipse(
      pupilX,
      pupilY,
      pupilWidth,
      pupilHeight,
      0,
      0,
      2 * Math.PI
    );
    ctx.fillStyle = '#000000'; // Black pupil
    ctx.fill();
    ctx.closePath();
  };

  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'center',
        backgroundColor: isCatEyeMode ? '#000000' : '#FFFFFF',
        minHeight: '100vh',
        color: isCatEyeMode ? '#FFFFFF' : '#000000',
      }}
    >
      <h1>Object Tracking App</h1>
      <div style={{ marginTop: '20px' }}>
        <button onClick={() => setIsCatEyeMode((prev) => !prev)}>
          {isCatEyeMode ? 'Switch to Debug Mode' : 'Switch to Cat Eye Mode'}
        </button>
      </div>
      {model ? (
        <>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 640,
                height: 480,
                facingMode: 'user',
              }}
              style={{
                width: '640px',
                height: '480px',
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0, // Make it transparent but keep it in the DOM
              }}
            />
            <canvas
              ref={canvasRef}
              style={{
                position: 'relative',
                width: '620px',
                height: '480px',
              }}
            />
          </div>
          {!isCatEyeMode && (
            <>
              <div style={{ marginTop: '20px' }}>
                <h2>Coordinates of Tracked Objects:</h2>
                {coordinatesList.length > 0 ? (
                  coordinatesList.map((coord, index) => (
                    <p key={index}>
                      {coord.class}: X: {coord.x}, Y: {coord.y}, Area:{' '}
                      {coord.area.toFixed(2)}
                    </p>
                  ))
                ) : (
                  <p>No objects detected.</p>
                )}
              </div>
              <div style={{ marginTop: '20px' }}>
                <h2>Adjust Image Settings:</h2>
                <div>
                  <label>
                    Brightness:
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={brightness}
                      onChange={(e) => setBrightness(parseFloat(e.target.value))}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Contrast:
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={contrast}
                      onChange={(e) => setContrast(parseFloat(e.target.value))}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Saturation:
                    <input
                      type="range"
                      min="0"
                      max="2"
                      step="0.1"
                      value={saturate}
                      onChange={(e) => setSaturate(parseFloat(e.target.value))}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Hue:
                    <input
                      type="range"
                      min="-180"
                      max="180"
                      step="10"
                      value={hue}
                      onChange={(e) => setHue(parseFloat(e.target.value))}
                    />
                  </label>
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <h2>Pupil Configuration:</h2>
                <div>
                  <label>
                    Pupil Width Ratio:
                    <input
                      type="range"
                      min="0.05"
                      max="0.2"
                      step="0.005"
                      value={pupilWidthRatio}
                      onChange={(e) =>
                        setPupilWidthRatio(parseFloat(e.target.value))
                      }
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Min Pupil Height Ratio:
                    <input
                      type="range"
                      min="0.05"
                      max="0.2"
                      step="0.005"
                      value={minPupilHeightRatio}
                      onChange={(e) =>
                        setMinPupilHeightRatio(parseFloat(e.target.value))
                      }
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Pupil Scale:
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={pupilScale}
                      onChange={(e) =>
                        setPupilScale(parseFloat(e.target.value))
                      }
                    />
                  </label>
                </div>
                <div>
                  <label>
                    Max Pupil Speed:
                    <input
                      type="range"
                      min="1"
                      max="50"
                      step="1"
                      value={maxPupilSpeed}
                      onChange={(e) =>
                        setMaxPupilSpeed(parseFloat(e.target.value))
                      }
                    />
                  </label>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <h2>Loading model, please wait...</h2>
      )}
    </div>
  );
};

export default App;
